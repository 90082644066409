body {
  font-family:
    'Overpass',
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Overpass;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;

  /* width */
  ::-webkit-scrollbar {
    @apply w-2 pl-1;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-inherit;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-300 dark:bg-gray-500 rounded-xl;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400 dark:bg-neutral-50;
  }
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
